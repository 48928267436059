import React from "react";

function Items() {
  return (
    <div className="container items-container">
      {/*
      <div className="col-12">
        
        <div className="row">
          <img src="/img/mic.png" />
        </div>
        <div className="row item-title">Gesprek opnemen</div>
        <div className="row item-desc">
          <div>
            Gebruik onze gratis gespreksopname optie wanneer u belt. Belt u
            vanaf een mobiele telefoon, dan ontvangt u na afloop van uw gesprek
            een gratis sms met een directe link naar uw gespreksopname.
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="row">
          <img src="/img/chat.png" />
        </div>
        <div className="row item-title">Gratis SMS</div>
        <div className="row item-desc">
          <div>
            Als u belt vanaf een mobiele telefoon, sturen we u een sms met het
            directe contactnummer van het merk waarmee u verbinding wilt maken.
          </div>
        </div>
      </div>
  */}
      <div className="col-12">
        <div className="row">
          <img src="/img/glass.png" />
        </div>
        <div className="row item-title">Gesprek opnemen</div>
        <div className="row item-desc">
          <div>
            Bedrijven verbergen vaak hun directe telefoonnummers. We maken het u
            gemakkelijk om snel verbinding te maken met het bedrijf dat u wilt
            spreken, zonder dat u naar hun nummer hoeft te zoeken.
          </div>
        </div>
      </div>
    </div>
  );
}
export default Items;
