import React from "react";

function Divider() {
  return (
    <div className="container">
      <div className="row nav-row">
        <div>Onze service</div>
      </div>
    </div>
  );
}
export default Divider;
