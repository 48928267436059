import React, { useState } from "react";

function ContactForm() {
  const [error, setError] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  function submit() {
    if (name === "" || email === "" || message === "") {
      setError("Ontbrekende gegevens");
      return;
    }

    alert("Bericht Verzonden");
  }
  return (
    <div className="container-sm contact-container">
      <form action="javascript:void()">
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">
            Naam
          </label>
          <input
            type="text"
            class="form-control"
            id="name"
            aria-describedby="emailHelp"
            onChange={(e) => [setName(e.target.value), setError("")]}
          />
        </div>
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">
            Email
          </label>
          <input
            type="email"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            onChange={(e) => [setEmail(e.target.value), setError("")]}
          />
        </div>

        <div class="mb-3">
          <label for="exampleFormControlTextarea1" class="form-label">
            Uw bericht
          </label>
          <textarea
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
            onChange={(e) => [setMessage(e.target.value), setError("")]}
          ></textarea>
        </div>
        {error !== "" && <div className="error-text mb-3">{error}</div>}
        <button type="submit" class="btn btn-primary" onClick={() => submit()}>
          Versturen
        </button>
      </form>
    </div>
  );
}
export default ContactForm;
