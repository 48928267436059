import React from "react";
import Company from "./Company";
import Divider from "./Divider";
import Items from "./Items";
import TextBlock from "./TextBlock";
import Contact from "./Contact";

function Home(props) {
  return (
    <>
      <Contact data={props.data} />
      <Company data={props.data} />
      <Divider />
      <Items />
      <TextBlock />
    </>
  );
}
export default Home;
