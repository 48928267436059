import React, { useEffect, useState } from "react";

function Company(props) {
  const [q, setQ] = useState("");

  useEffect(() => {
    if (props.data && props.data.brandName) {
      setQ(props.data.brandName);
    }
  });
  return (
    <div className="container company-container pt-5">
      <div className="row title-text-sub">
        <div>
          {props.data && props.data.brandName ? props.data.brandName : ""}
        </div>
      </div>
      <div
        className="row website-bar-row"
        onClick={() =>
          (window.location.href =
            props.data && props.data.Website ? props.data.Website : "")
        }
      >
        <div className="website-bar">
          <div>Website</div>
          <div>
            <img src="/img/right-arrow.png" />
          </div>
        </div>
      </div>
      <div className="row pt-5">
        <iframe
          width="100%"
          height="300"
          style={{ border: 0 }}
          loading="lazy"
          allowfullscreen
          referrerpolicy="no-referrer-when-downgrade"
          src={
            "https://www.google.com/maps/embed/v1/search?key=AIzaSyBKyB4R_vAoQVWQURy2XiXa2sKaCQGWdDY&q=" +
            q
          }
        ></iframe>
      </div>
      <div className="row location-bar-row pt-5 pb-5">
        <div className="location-bar">
          <div className="container p-0">
            <div className="row">
              <div>Locatie</div>
            </div>
            <div className="row location-bar-sub pt-1">
              <div>
                {props.data && props.data.Address ? props.data.Address : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Company;
