import NavBar from "./Components/NavBar";
import Contact from "./Components/Contact";
import "./App.css";
import Home from "./Components/Home";
import Footer from "./Components/Footer";
import { useEffect, useState } from "react";
import { Routes, Route, Link } from "react-router-dom";
import ContactForm from "./Components/ContactForm";

const data = [
  {
    brandName: "Ziggo",
    websiteNumber: "09060160,1000",
    slug: "91j2i",
    directNumber: "0900-1884",
    type: "",
    directNumberInt: "0031881212817",
    Website: "https://ziggo.nl",
    Address: "Boven Vredenburg passage 124, Utrecht, 3511, WR Netherlands",
  },
  {
    brandName: "ING Bank",
    websiteNumber: "09060160,1001",
    slug: "f4tcn",
    directNumber: "020 2288888",
    type: "",
    directNumberInt: "0031202288888",
    Website: "https://www.ing.nl/particulier/index.html",
    Address: "PO Box 1800, 1000 BV Amsterdam, Netherlands",
  },
  {
    brandName: "Telfort",
    websiteNumber: "09060160,1002",
    slug: "el6lu",
    directNumber: "0900 9596",
    type: "",
    directNumberInt: "0031887650200",
    Website: "https://www.kpn.com/",
    Address: "Royal KPN N.V. Wilhelminakade 123 3072 AP Rotterdam",
  },
  {
    brandName: "Essent",
    websiteNumber: "09060160,1003",
    slug: "v2x2k",
    directNumber: "0900-1550",
    type: "Still premium",
    directNumberInt: "00319001550",
    Website:
      "https://www.essent.nl/content/particulier/klantenservice/index.html",
    Address: "Willemsplein 4, 5211AK s-Hertogenbosch, Netherlands",
  },
  {
    brandName: "KPN",
    websiteNumber: "09060160,1004",
    slug: "q44w5",
    directNumber: "0800-0402",
    type: "",
    directNumberInt: "00318000402",
    Website: "https://www.kpn.com/",
    Address: "Royal KPN N.V. Wilhelminakade 123 3072 AP Rotterdam",
  },
  {
    brandName: "PostNL",
    websiteNumber: "09060160,1005",
    slug: "9j8y5",
    directNumber: "088-22 55 555",
    type: "",
    directNumberInt: "0031882255555",
    Website: "https://www.postnl.nl/en/",
    Address: "Princess Beatrixlaan 23, 2595AK The Hague, The Netherlands",
  },
  {
    brandName: "NS",
    websiteNumber: "09060160,1006",
    slug: "2ls0y",
    directNumber: "030-751 51 55",
    type: "",
    directNumberInt: "0031307515155",
    Website: "https://www.ns.nl/",
    Address: "Avenue van Puntenburg 100, 3511ER Utrecht, Netherlands",
  },
  {
    brandName: "Nuon",
    websiteNumber: "09060160,1007",
    slug: "sm5xl",
    directNumber: "0900-0808",
    type: "",
    directNumberInt: "0031268450271",
    Website: "https://www.vattenfall.nl/",
    Address: "PO Box 40021, 6803HA Haarlem, The Netherlands",
  },
  {
    brandName: "Tele2",
    websiteNumber: "09060160,1008",
    slug: "i5dkd",
    directNumber: "020-754 444",
    type: "",
    directNumberInt: "0031207544444",
    Website: "https://www.tele2.nl/",
    Address: "PO Box 289, 2400 AG Alphen aan den Rijn, The Netherlands",
  },
  {
    brandName: "SNS Bank",
    websiteNumber: "09060160,1009",
    slug: "o074q",
    directNumber: "088-385 53 72",
    type: "",
    directNumberInt: "0031883855372",
    Website: "https://www.snsbank.nl/particulier/home.html",
    Address: "Croeselaan 1, 3521BJ Utrecht, The Netherlands",
  },
  {
    brandName: "Vodafone",
    websiteNumber: "09060160,1010",
    slug: "lyfep",
    directNumber: "0800 0094",
    type: "",
    directNumberInt: "0031433555555",
    Website: "https://www.vodafone.nl/",
    Address: "Avenue Ceramique 300, 6221 KX Maastricht, Netherlands",
  },
  {
    brandName: "Eneco",
    websiteNumber: "09060160,1011",
    slug: "cqurz",
    directNumber: "088-8 955 955",
    type: "",
    directNumberInt: "0031888955955",
    Website: "https://www.eneco.nl/",
    Address: "Marten Meesweg 5, 3068 AV Rotterdam, Netherlands",
  },
  {
    brandName: "Oxxio",
    websiteNumber: "09060160,1012",
    slug: "a3sta",
    directNumber: "088-89 55 999",
    type: "",
    directNumberInt: "0031888955999",
    Website: "https://www.oxxio.nl/",
    Address: "Marten Meesweg 5, 3068 AV Rotterdam,Netherlands",
  },
  {
    brandName: "Belsimpel",
    websiteNumber: "09060160,1013",
    slug: "yhwhy",
    directNumber: "050 210 34 04",
    type: "",
    directNumberInt: "0031502103404",
    Website: "https://www.belsimpel.nl/",
    Address: "Waagstraat 1, 9712JX Groningen, Netherlands",
  },
  {
    brandName: "T-Mobile",
    websiteNumber: "09060160,1014",
    slug: "ep5p4",
    directNumber: "06-2400 1200",
    type: "",
    directNumberInt: "0031624001200",
    Website: "https://www.t-mobile.nl/",
    Address: "Payglop 7, 1811 HM Alkmaar, The Netherlands",
  },
  {
    brandName: "Aldi",
    websiteNumber: "09060160,1015",
    slug: "3kv7h",
    directNumber: "034 5477100",
    type: "",
    directNumberInt: "0031345477100",
    Website: "https://www.aldi.nl/",
    Address: "Ohmweg 3, 4104 BM Culemborg, Netherlands",
  },
  {
    brandName: "Toto",
    websiteNumber: "09060160,1016",
    slug: "e3wc0",
    directNumber: "070 550805",
    type: "",
    directNumberInt: "003170550805",
    Website: "https://www.toto.nl/klantenservice",
    Address: "Laan van Hoornwijk 55, 2289 DG Rijswijk, Netherlands",
  },
  {
    brandName: "Specsavers",
    websiteNumber: "09060160,1017",
    slug: "akeo0",
    directNumber: "035 6288311",
    type: "",
    directNumberInt: "0031356288311",
    Website: "https://www.specsavers.nl/",
    Address: "Huizermaatweg 320-322, 1271LJ Houses, Netherlands",
  },
];

function App() {
  const [selectedCompany, setSelectedCompany] = useState();

  useEffect(() => {
    const search = window.location.search;
    const id = window.location.pathname.replace("/", "");

    setSelectedCompany(data[0]);

    for (let i = 0; i < data.length; i++) {
      console.log(data[i]);
      if (data[i].slug === id) {
        setSelectedCompany(data[i]);
      }
    }
  }, []);
  return (
    <div class="container-sm p-0">
      <NavBar />
      <Routes>
        <Route path="/contact" element={<ContactForm />} />
        <Route path="*" element={<Home data={selectedCompany} />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
