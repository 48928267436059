import React from "react";

function Footer() {
  return (
    <div className="container footer-container">
      <div className="row bold-font pb-5">
        Bel
        <br />
        Klantenservices
      </div>
      <div
        className="row"
        style={{ cursor: "pointer" }}
        onClick={() => (window.location.href = "/contact")}
      >
        Neem contact op
      </div>
    </div>
  );
}
export default Footer;
