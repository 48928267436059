import React, { useState } from "react";

function Contact(props) {
  function call() {
    window.open("tel:" + props.data.websiteNumber, "_self");
  }
  return (
    <div className="container contact-container">
      <div className="row contact-title-row title-text">
        <div>
          <div>Contact</div>{" "}
          <div className="blue-text">
            {props.data && props.data.brandName ? props.data.brandName : ""}
          </div>{" "}
        </div>
      </div>
      <div className="row">
        <div className="title-text-sub">Klantenservice</div>
      </div>
      <div className="row contact-content">
        <div>
          Dit is een doorstuurservice van Mi Telecom Ltd (UK). U betaalt 100 cpm
          voor het gesprek, ook na de doorschakeling. Snel aangesloten via
          bel-klantenservices.nl
        </div>
      </div>
      <div className="row btn-row pt-5">
        <button id="CallButtonCTA" className="call-btn" onClick={() => call()}>
          <img className="call-btn-img" src="/img/telephone.png" />
          Bel Nu
        </button>
      </div>
      <div className="row contact-content pb-5">
        <div>
          Met de doorschakelservice van bel-klantenservices.nl kunt u snel in
          contact komen met het gewenste merk zonder dat u op hun nummer hoeft
          te zoeken.
          <br />
          <br /> Het klantenservicenummer dat u zoekt is ook direct beschikbaar
          tegen een voordeliger tarief.
        </div>
      </div>
    </div>
  );
}
export default Contact;
