import React from "react";

function NavBar() {
  return (
    <div className="container">
      <div className="row nav-row">
        <div>
          {"Bel"} <br /> {"Klantenservices"}
        </div>
      </div>
    </div>
  );
}
export default NavBar;
