import React from "react";

function TextBlock() {
  return (
    <div className="container text-block">
      <div className="row">
        <div>
          0906-0160 van Bel-Kalantenservice is een doorschakelservice. De
          service kost 100 cent per minuut plus eventuele extra belkosten.
          <br />
          <br />
          Mi Telecom Limited is gevestigd in het Verenigd Koninkrijk, 14
          Hemmels, Laindon, Basildon, Essex, SS15 6ED. We zijn niet aangesloten
          bij een merk dat op onze site wordt vermeld. Hun directe
          telefoonnummer is mogelijk tegen een lagere prijs beschikbaar op hun
          website.
        </div>
      </div>
    </div>
  );
}
export default TextBlock;
